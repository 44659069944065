export default {
  name: 'Banner',
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    ctaText: {
      type: String
    },
    ctaAction: {
      type: Function,
      default: () => {}
    },
    backgroundUrl: {
      type: String,
    },
    caption: {
      type: String,
    },
  },
  computed: {
    rootStyle() {
      return {
        backgroundImage: `url(${this.backgroundUrl})`,
        backgroundSize: 'cover'
      };
    }
  }
};
